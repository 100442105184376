<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-progress-linear
                  :active="getAuthLoading"
                  :indeterminate="getAuthLoading"
                  absolute
                  top
                  color="primary"
                ></v-progress-linear>
                <v-form
                  ref="form"
                  v-model="validForm"
                  v-on:submit.prevent="formSubmit">
                  <v-card-text class="text-center">
                    <v-avatar size="60" class="mb-4">
                        <img src="@/assets/images/logo.svg" alt="" />
                    </v-avatar>
                    <p v-if="!success" class="text--disabled font-weight-medium">
                      Reset password
                    </p>
                    <v-alert v-if="alertMessage" :color="alertColor + ' lighten-1'" dark>
                      {{ alertMessage }}
                    </v-alert>
                    <template v-if="!success">
                      <v-alert v-if="errors.length" color="red lighten-1" dark>
                        <ul class="password-errors">
                          <li v-for="error in errors" :key="error">{{ error }}</li>
                        </ul>
                      </v-alert>
                      <v-text-field
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        name="input-10-2"
                        label="Password"
                        :rules="nameRules"
                        v-model="password"
                        @click:append="show = !show"
                      ></v-text-field>
                      <v-btn dark class="mb-4" block color="primary"
                        type="submit" :disabled="getAuthLoading">Submit</v-btn>
                    </template>
                    <div class="d-flex justify-around">
                      <v-btn text small color="primary" :disabled="getAuthLoading"
                        to="/auth/sign-in">
                        Sign In
                      </v-btn>
                    </div>
                </v-card-text>
                </v-form>
            </base-card>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'reset-password',
  metaInfo: {
    title: 'Reset Password'
  },
  data() {
    return {
      validForm: false,
      success: false,
      alertMessage: null,
      alertColor: null,
      hash: _.get(this.$route, "query.hash"),
      email: _.get(this.$route, "query.email"),
      show: false,
      password: null,
      errors: [],
      nameRules: [v => !!v || "Required."]
    }
  },
  computed: {
    ...mapGetters(['getAuthLoading', 'getAuthError']),
  },
  methods: {
      ...mapActions(['resetPassword']),
      validatePassword() {
          this.errors = [];
          if (this.password.length < 8) {
            this.errors.push("Your password must be at least 8 characters");
          }
          if (this.password.length > 32) {
            this.errors.push("Your password must be at max 32 characters");
          }
          if (this.password.search(/[a-z]/) < 0) {
            this.errors.push("Your password must contain at least one lower case letter.");
          }
          if (this.password.search(/[A-Z]/) < 0) {
            this.errors.push("Your password must contain at least one upper case letter.");
          }
          if (this.password.search(/[0-9]/) < 0) {
            this.errors.push("Your password must contain at least one digit.");
          }
          if (this.password.search(/[!@#\$%\^&\*_]/) < 0) {
            this.errors.push("Your password must contain at least special char from -[ ! @ # $ % ^ & * _ ]");
          }
          if (this.errors.length > 0) {
            return false;
          }
          return true;
      },
      async formSubmit() {
        if (!this.validatePassword(this.password)) {
          return;
        };

        const response = await this.resetPassword({
          email: this.email,
          hash: this.hash,
          password: this.password
        });
        if (_.get(response, 'data')) {
          this.alertMessage = response.data;
          this.alertColor = 'green';
          this.success = true;
        }
      },
      showSnackbar(message, duration = 2000) {
        this.snackbar = true;
        this.snackbarMessage = message;
        this.snackbarTimeout = duration;
      }
  },
  watch: {
    getAuthError(errorMessage) {
      this.alertMessage = errorMessage;
      this.alertColor = 'red';
    }
  }
}
</script>
<style lang="scss" scoped>
.password-errors {
  font-size: 14px;
  text-align: left;
}
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
